import React from 'react';
import {Redirect} from '@reach/router';
import WorkspaceCreator from 'containers/WorkspaceCreator';
import useGetUserAndUserWorkspacesQuery from './hooks/useGetUserAndUserWorkspacesQuery';

function WorkspaceRedirector (props) {
  const {loading, error, data} = useGetUserAndUserWorkspacesQuery();

  if (loading) {
    return null;
  }

  if (error) {
    return <div>Error loading workspaces</div>;
  }

  if (data && data.user.workspaces.length > 0) {
    const isLoggedInWithSSO = Boolean(data.user.loggedInWithSSOProviderId);

    const {urlName} = !isLoggedInWithSSO
      ? getFirstNonSSOEnforcedWorkspace(data.user)
      : getWorkspaceFromSSO(data.user);

    return <Redirect to={`/${urlName}`} noThrow={true} />;
  }

  return <WorkspaceCreator/>;
}

function getFirstNonSSOEnforcedWorkspace (user) {
  return user.workspaces.find((workspace) =>
    !workspace.ssoProviders || workspace.ssoProviders.length === 0 ||
      workspace.ssoProviders.every((provider) => !provider.isEnforcedForUser));
}

function getWorkspaceFromSSO (user) {
  return user.workspaces.find((workspace) =>
    workspace.ssoProviders.find((provider) =>
      provider.id === user.loggedInWithSSOProviderId));
}

export default WorkspaceRedirector;
